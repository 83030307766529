/**
 * Converts an object to a URL-encoded form data string.
 *
 * @param {Object} obj - The object to be converted.
 * @param {string} [prefix=''] - The prefix to be used for nested keys.
 * @returns {string} - The URL-encoded form data string.
 */
function objectToFormData(obj, prefix = '') {
    const params = new URLSearchParams();

    function buildParams(obj, prefix) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) { // Fix here
                const value = obj[key];
                const fullKey = prefix ? `${prefix}[${key}]` : key;

                if (typeof value === 'object' && value !== null) {
                    buildParams(value, fullKey); // Recursion for nested objects
                } else {
                    params.append(fullKey, value);
                }
            }
        }
    }

    buildParams(obj, prefix);
    return params.toString();
}

/**
 * Sends an AJAX POST request with the given data.
 *
 * @param {Object} data - The data to be sent in the request body.
 * @param {string} data.security - Security token for the request.
 * @returns {Promise<Response>} The response from the fetch request.
 */
export async function postAjax(data = {}) {
    data.security = CGIApplicationAjax.security;

    const response = await fetch(CGIApplicationAjax.ajaxurl, {
        method: 'POST',
        mode: 'same-origin',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'manual',
        referrerPolicy: 'no-referrer',
        body: objectToFormData(data),
    });
    return response;
}

export async function postJson(data = {}) {
    const url = new URL(CGIApplicationAjax.ajaxurl);
    url.searchParams.append('action', data.action); // Add action to URL
    url.searchParams.append('security', CGIApplicationAjax.security); // Add nonce to URL

    const response = await fetch(url.toString(), {
        method: 'POST',
        mode: 'same-origin',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'manual',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data),
    });

    return response;
}
