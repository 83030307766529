import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import { postAjax } from '../helpers';

const CreditLimitSign = ({transaction = ''}) => {
    const referenceId = useRef('')
    const autostartToken = useRef('');
    const transactionId = useRef(transaction);
    const stopPoll = useRef(false);
    const cgiPoll = useRef(null);
    const isMobile = useRef(false);
    const isiOS = useRef(false);
    const [qrData, setQrData] = useState('');
    const [header, setHeader] = useState('Signering startar...');
    const [instructions, setInstructions] = useState('');
    const [showOpenBankId, setShowOpenBankId] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        // If transactionId is already set, start polling immediately
        if (transactionId.current) {
            poll();
            return;
        }

        // Init signing
        postAjax({ action: 'credit_limit_sign', params: { qr: true } })
            .then((response) => response.json())
            .then((jsonData) => {
                if (jsonData.success) {
                    referenceId.current = jsonData.data.referenceId;
                    autostartToken.current = jsonData.data.autostartToken;
                    transactionId.current = jsonData.data.transactionId;
                    isMobile.current = jsonData.data.isMobile;
                    isiOS.current = jsonData.data.isiOS;

                    if (isMobile.current) {
                        if (isiOS.current) {
                            location.href = `bankid:///?${autostartToken.current}&redirect=${encodeURIComponent(CGILimitAjax.limitApplication + 'poll/?transactionId=' + transactionId.current)}`;
                        } else {
                            location.href = `bankid:///?${autostartToken.current}&redirect=null`;
                        }
                    } else {
                        setQrData(jsonData.data.qrData);
                    }

                    poll();
                } else {
                    throw new Error(jsonData?.data?.message ?? 'Network response was not ok');
                }
            })
            .catch((error) => {
                handleError(error);
            });

        return () => {
            clearTimeout(cgiPoll.current);
            stopPoll.current = true;
        };
    }
    , []);

    /**
     * Schedules the next polling attempt if polling has not been stopped.
     * Uses a timeout to call the `poll` function after 1 second.
     *
     * @function scheduleNextPoll
     * @returns {void}
     */
    const scheduleNextPoll = () => {
        if (!stopPoll.current) {
            cgiPoll.current = setTimeout(poll, 1000);
        }
    }

    /**
     * Handles the completion of a specific process or action.
     * Sets the state to indicate that the process is complete.
     *
     * @function
     * @returns {void}
     */
    const handleComplete = () => {
        setIsComplete(true);
    };

    /**
     * Handles the user sign-in process using BankID.
     *
     * @param {Object} params - The parameters for the function.
     * @param {string} params.instructions - Instructions to display to the user.
     *
     * This function updates the UI to prompt the user to authenticate with BankID,
     * clears any existing QR data, hides the "Open BankID" prompt, and schedules
     * the next polling operation.
     */
    const handleUserSign = ({instructions}) => {
        setHeader('Nu kan du legitimera dig med BankID.');
        setInstructions(instructions);
        setQrData('');
        setShowOpenBankId(false);

        scheduleNextPoll();
    };

    /**
     * Handles the outstanding transaction by updating the UI and scheduling the next poll.
     *
     * @param {Object} params - The parameters for the transaction.
     * @param {string} params.instructions - The instructions to display in the header.
     * @param {string} params.qrData - The QR code data to display (used for non-mobile devices).
     */
    const handleOutstandingTransaction = ({instructions, qrData}) => {
        setHeader(instructions);

        if (isMobile.current) {
            setShowOpenBankId(true);
            setShowCancel(true);
        } else {
            setQrData(qrData);
            setShowCancel(true);
        }

        scheduleNextPoll();
    };

    /**
     * Handles errors during the signing process by updating the UI state.
     *
     * @param {string} error - The error message to display.
     */
    const handleError = (error) => {
        setHeader('Fel vid signering');
        setIsError(true);
        setErrorMessage(error);
    };

    /**
     * Aborts the BankID signing process and resets the UI state.
     *
     * This function stops the polling process, clears the QR data, and resets
     * various state variables related to the signing process. It also sends
     * an AJAX request to notify the server about the aborted signing process.
     * If the server responds successfully or an error occurs, the user is
     * redirected to the limit application page.
     *
     * @function
     * @returns {void}
     */
    const abortSign = (e) => {
        e.preventDefault();
        stopPoll.current = true;
        clearTimeout(cgiPoll.current);
        setHeader('Avbryter...');
        setInstructions('');
        setQrData('');
        setShowOpenBankId(false);
        setShowCancel(false);
        setIsComplete(false);
        setIsError(false);
        setErrorMessage('');

        postAjax({ action: 'credit_limit_sign_abort', params: { id: transactionId.current } })
            .then((response) => response.json())
            .then(() => {
                window.location.href = CGILimitAjax.limitApplication;
            })
            .catch(() => {
                window.location.href = CGILimitAjax.limitApplication;
            });
    };

    /**
     * Polls the server to check the status of a transaction and handles the response accordingly.
     *
     * The function sends an AJAX POST request to the server with the transaction ID and processes
     * the response to determine the current progress status of the transaction. Based on the status,
     * it invokes appropriate handlers or schedules the next polling attempt.
     *
     * @function
     * @returns {void}
     *
     * @throws {Error} Throws an error if the server response is unsuccessful or if there is a network issue.
     *
     * @example
     * // Example usage:
     * poll();
     *
     * Progress statuses handled:
     * - 'complete': Calls `handleComplete()` to finalize the transaction.
     * - 'userSign': Calls `handleUserSign()` with instructions for the user.
     * - 'outstandingTransaction' or 'noClient': Calls `handleOutstandingTransaction()` with instructions and QR data.
     * - 'error': Calls `handleError()` with an error message.
     * - Default: Schedules the next polling attempt.
     */
    const poll = () => {
        if (stopPoll.current) {
            return;
        }
        postAjax({ action: 'credit_limit_sign_transactions', params: { id: transactionId.current } })
            .then((response) => response.json())
            .then((jsonData) => {
                if (stopPoll.current) {
                    return;
                }
                if (jsonData.success) {
                    switch (jsonData.data.progressStatus) {
                        case 'complete':
                            handleComplete();
                            break;
                        case 'userSign':
                            handleUserSign({
                                instructions: jsonData.data?.progressStatusInstruction ?? ''
                            });
                            break;
                        case 'outstandingTransaction':
                        case 'noClient':
                            handleOutstandingTransaction({
                                instructions: jsonData.data?.progressStatusInstruction ?? '',
                                qrData: jsonData.data?.qrData ?? ''
                            });
                            break;
                        case 'error':
                            handleError(jsonData.data?.message ?? 'Oväntat fel');
                            break;
                        default:
                            scheduleNextPoll();
                    }
                } else {
                    throw new Error(jsonData?.data?.message ?? 'Network response was not ok');
                }
            })
            .catch((error) => {
                handleError(error);
            });
    };

    if (isError) {
        return (
            <div>
                <h1>{header}</h1>
                <p>{errorMessage}</p>
                <a href={CGILimitAjax.limitApplication} className="et_pb_button cgi-submit-input" style={{ display: 'inline-flex', marginTop: '0.5rem' }}>OK</a>
            </div>
        )
    }

    if (isComplete) {
        return (
            <div>
                <h1>Tack för din beställning</h1>
                <p>Ditt kort kommer om ca. 1 vecka. Extrakortet kommer att synas inom några dagar här på Mina Sidor.</p>
                <a href={CGIAjax.myPagesOverview} className="et_pb_button cgi-submit-input" style={{ display: 'inline-flex', marginTop: '0.5rem' }}>OK</a>
            </div>
        )
    }

    return (
        <div>
            <h1>{header}</h1>
            <p>{instructions}</p>
            { qrData && <QRCodeSVG value={qrData} /> }
            { showOpenBankId && (
                <p>Klicka här för att<br /><a className="et_pb_button" href={`bankid:///?${autostartToken.current}&redirect=null`}>Starta BankID-appen</a></p>
            )}
            { showCancel && (
                <p><a className="cgi-abort-sign" onClick={abortSign} href="#">Avbryt</a></p>
            )}
        </div>
    );
};

CreditLimitSign.propTypes = {
    initAction: PropTypes.func.isRequired,
    pollAction: PropTypes.func.isRequired,
    abortAction: PropTypes.func.isRequired,
    transaction: PropTypes.string,
};

export default CreditLimitSign;
