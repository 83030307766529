import React from 'react';
import { createRoot } from 'react-dom/client';
import CreditLimitSign from './components/credit-limit-sign.jsx';

jQuery(function ($) {
    $('#limit-form-credit-limit').validate({
        rules: {
            amount: "required",
            alt_offer_m: "required",
        },
        messages: {
            amount: "Ange önskad kreditgräns",
            alt_offer_m: "Vänligen välj layout",


        },
        submitHandler: function (form, e) {
            sendLimitApplicationCreditLimit(form, e);
        }
    });

    $('#limit-form-about-me').validate({
        rules: {
            amount: "required",
            email: {
                required: true,
                email: true,
            },
            phone_number: {
                required: true,
                validPhoneNumber: "SE",
            },
            civil_status: "required",
            form_of_employment: "required",
            monthly_income: {
                required: true,
                number: true,
            },
            alt_offer_m: "required",
        },
        messages: {
            amount: "Ange önskad kreditgräns",
            email: {
                required: "Ange din e-mail",
                email: "Ange en giltig e-mailadress",
            },
            phone_number: {
                required: "Ange ditt telefonnummer",
                validPhoneNumber: "Ange ett giltigt telefonnummer"
            },
            civil_status: "Ange din civilstatus",
            form_of_employment: "Ange din anställningsform",
            monthly_income: {
                required: "Ange månadsinkomst före skatt",
                number: "Ange endast siffror"
            },
            alt_offer_m: "Vänligen välj layout",


        },
        submitHandler: function (form, e) {
            sendLimitApplicationAboutMe(form, e);
        }
    });

    $('#limit-form-economy').validate({
        rules: {
            alt_offer_e: {
                required: {
                    depends: function () {
                        return ($('input[name="extra_card"]:checked').data('extra-card') == "yes");
                    }
                }
            },
            co_pnr: {
                isValidSwedishSSN: true,
                number: true,
                required: {
                    depends: function () {
                        return ($('input[name="extra_card"]:checked').data('extra-card') == "yes");
                    }
                }
            },
            monthly_mortgage_expenses: {
                required: {
                    depends: function () {
                        return ($('input[name="have_mortgage"]:checked').val() == "true");
                    }
                },
            },
            monthly_loan_expenses: {
                required: {
                    depends: function () {
                        return ($('input[name="have_other_loans"]:checked').val() == "true");
                    },
                },
            },
            capital_debt_loans: {
                required: {
                    depends: function () {
                        return ($('input[name="have_other_loans"]:checked').val() == "true");
                    },
                },
            },
            personal_guarantee_amount: {
                required: {
                    depends: function () {
                        return ($('input[name="have_personal_guarantee"]:checked').val() == "true");
                    },
                },
            },
            adults_in_household: {
                required: true,
                number: true,
                min: 0,
            },
            children_in_household: {
                required: true,
                number: true,
                min: 0,
            },
            form_of_housing: "required",
            rent_amount: "required",
            number_of_cars: {
                required: true,
                number: true,
                min: 0,
                max: 15,
            },
            payment_remark: "required",
            have_mortgage: "required",
            have_other_loans: "required",
            have_personal_guarantee: "required",
        },
        messages: {
            alt_offer_e: {
                required: "Vänligen välj layout",
            },
            co_pnr: {
                required: "Ange extrakortsökandens personnummer 12 siffror",
                isValidSwedishSSN: "Kontrollera personnumret",
                number: "Ange endast siffror",
            },
            extra_card: {
                required: "Vänligen välj om du vill beställa extrakort eller ej",
            },
            personal_guarantee_amount: "Ange belopp för borgensåtagande",
            adults_in_household: {
                required: "Ange antal vuxna i hushållet",
                number: "Ange endast siffror",
                min: "Ange minst 0 vuxna",
            },
            children_in_household: {
                required: "Ange antal barn under 18 år i hushållet",
                number: "Ange endast siffror",
                min: "Ange minst 0 barn",
            },
            form_of_housing: "Ange typ av boende",
            monthly_loan_expenses: "Ange månadskostnad för lån",
            monthly_mortgage_expenses: "Ange månadskostnad för bolån",
            capital_debt_loans: "Ange kapitalskuld för lån",
            rent_amount: "Ange hyreskostnad",
            number_of_cars: {
                required: "Ange antalet bilar",
                number: "Ange endast siffror",
                min: "Ange minst 0 bilar",
                max: "Ange max 15 bilar",
            },
            payment_remark: "Ange om du har betalningsanmärkningar",
            have_mortgage: "Ange om du har bolån",
            have_other_loans: "Ange om du har andra lån",
            have_personal_guarantee: "Ange om du har borgensåtagande",
        },
        submitHandler: function (form, event) {
            sendLimitApplicationEconomy(form, event);
        }
    });

    $('#limit-form-summary').validate({
        rules: {
            villkor: "required",
        },
        messages: {
            villkor: "Vänligen acceptera villkoren",
        },
        submitHandler: function (form, event) {
            sendLimitApplication(form, event);
        }
    });


    /**
     * Handles the submission of the credit limit step of the application form.
     * Prevents the default form submission, shows a loader, serializes the form data,
     * and sends it via an AJAX POST request to save the application data.
     * Redirects to the next step on success or displays an error message on failure.
     *
     * @param {HTMLFormElement} form - The form element being submitted.
     * @param {Event} e - The event object associated with the form submission.
     */
    function sendLimitApplicationCreditLimit(form, e) {
        e.preventDefault();
        $('.application-loader').removeClass('hidden');

        var form_data = $(form).serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        var data = {
            action: 'save_application_data',
            security: CGILimitAjax.security,
            data: {
                credit_limit_amount: form_data.credit_limit_amount,
            }
        }

        $.post(CGILimitAjax.ajaxurl, data, function (response) {
            if (response.success) {
                window.location.assign(CGILimitAjax.limitApplication + 'om-mig/');
            } else {
                try {
                    $('.application-form-wrapper .cgi-status').html(response.data.message).removeClass('hidden');
                }
                catch {
                    $('.application-form-wrapper .cgi-status').html('Ett oväntat fel har uppstått. Vänligen försök igen eller kontakta kundtjänst om felet återkommer.').removeClass('hidden');
                }
                $('.application-loader').addClass('hidden');
            }
        });
    }

    /**
     * Handles the submission of the first step of the application form.
     * Prevents the default form submission, shows a loader, serializes the form data,
     * and sends it via an AJAX POST request to save the application data.
     * Redirects to the next step on success or displays an error message on failure.
     *
     * @param {HTMLFormElement} form - The form element being submitted.
     * @param {Event} e - The event object associated with the form submission.
     */
    function sendLimitApplicationAboutMe(form, e) {
        e.preventDefault();
        $('.application-loader').removeClass('hidden');

        var form_data = $(form).serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        var data = {
            action: 'save_application_data',
            security: CGILimitAjax.security,
            data: {
                monthly_income: form_data.monthly_income,
                form_of_employment: form_data.form_of_employment,
                civil_status: form_data.civil_status,
                email: form_data.email,
                phone_number: form_data.phone_number,
            }
        }

        $.post(CGILimitAjax.ajaxurl, data, function (response) {
            if (response.success) {
                window.location.assign(CGILimitAjax.limitApplication + 'ekonomi/');
            } else {
                try {
                    $('.application-form-wrapper .cgi-status').html(response.data.message).removeClass('hidden');
                }
                catch {
                    $('.application-form-wrapper .cgi-status').html('Ett oväntat fel har uppstått. Vänligen försök igen eller kontakta kundtjänst om felet återkommer.').removeClass('hidden');
                }
                $('.application-loader').addClass('hidden');
            }
        });
    }

    /**
     * Handles the submission of the second step of the application form.
     * Prevents the default form submission, shows a loader, serializes the form data,
     * and sends it via an AJAX POST request to save the application data.
     *
     * @param {HTMLFormElement} form - The form element being submitted.
     * @param {Event} event - The event object associated with the form submission.
     */
    function sendLimitApplicationEconomy(form, event) {
        event.preventDefault();
        $('.application-loader').removeClass('hidden');

        var form_data = $(form).serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        var alt_offer = '';
        if (form_data.alt_offer_e != '' && form_data.extra_card != '') {
            if (form_data.alt_offer_m == 1 && form_data.alt_offer_e == 1) { alt_offer = 12; }
            if (form_data.alt_offer_m == 1 && form_data.alt_offer_e == 2) { alt_offer = 14; }
            if (form_data.alt_offer_m == 1 && form_data.alt_offer_e == 3) { alt_offer = 19; }
            if (form_data.alt_offer_m == 2 && form_data.alt_offer_e == 2) { alt_offer = 13; }
            if (form_data.alt_offer_m == 2 && form_data.alt_offer_e == 1) { alt_offer = 15; }
            if (form_data.alt_offer_m == 2 && form_data.alt_offer_e == 3) { alt_offer = 20; }
            if (form_data.alt_offer_m == 3 && form_data.alt_offer_e == 3) { alt_offer = 16; }
            if (form_data.alt_offer_m == 3 && form_data.alt_offer_e == 1) { alt_offer = 17; }
            if (form_data.alt_offer_m == 3 && form_data.alt_offer_e == 2) { alt_offer = 18; }
        }

        var data = {
            action: 'save_application_data',
            security: CGILimitAjax.security,
            data: {
                form_of_housing: form_data.form_of_housing,
                have_mortgage: form_data.have_mortgage == 'true' ? 1 : 0,
                have_other_loans: form_data.have_other_loans == 'true' ? 1 : 0,
                number_of_cars: form_data.number_of_cars,
                payment_remark: form_data.payment_remark == 'true' ? 1 : 0,
                adults_in_household: form_data.adults_in_household,
                children_in_household: form_data.children_in_household,
                rent_amount: form_data.rent_amount,
                have_personal_guarantee: form_data.have_personal_guarantee == 'true' ? 1 : 0,
            }
        }

        if (form_data.have_mortgage == 'true') {
            data.data.monthly_mortgage_expenses = form_data.monthly_mortgage_expenses;
        } else {
            data.data.monthly_mortgage_expenses = 'null';
        }

        if (form_data.have_other_loans == 'true') {
            data.data.monthly_loan_expenses = form_data.monthly_loan_expenses;
            data.data.capital_debt_loans = form_data.capital_debt_loans;
        } else {
            data.data.monthly_loan_expenses = 'null';
            data.data.capital_debt_loans = 'null';
        }

        if (form_data.have_personal_guarantee == 'true') {
            data.data.personal_guarantee_amount = form_data.personal_guarantee_amount;
        } else {
            data.data.personal_guarantee_amount = 'null';
        }

        if (alt_offer != '') {
            data.data.alt_offer = alt_offer;
        }

        $.post(CGILimitAjax.ajaxurl, data, function (response) {
            if (response.success) {
                window.location.assign(CGILimitAjax.limitApplication + 'sammanfattning/');
            } else {
                try {
                    $('.application-form-wrapper .cgi-status').html(response.data.message).removeClass('hidden');
                }
                catch {
                    $('.application-form-wrapper .cgi-status').html('Ett oväntat fel har uppstått. Vänligen försök igen eller kontakta kundtjänst om felet återkommer.').removeClass('hidden');
                }
                $('.application-loader').addClass('hidden');
            }
        });
    }

    /**
     * Send the application to Customer API.
     *
     * @param {HTMLFormElement} form - The form element that triggered the event.
     * @param {Event} event - The event object associated with the form submission.
     */
    function sendLimitApplication(form) {
        var data = {
            action: 'credit_limit',
            security: CGILimitAjax.security,
        }
        $('.application-loader').removeClass('hidden');

        var form_data = $(form).serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        $.post(CGILimitAjax.ajaxurl, data, function (response) {
            if (response.success) {
                if (response.data.approved_credit_limit > form_data.old_limit) {
                    window.location.assign(CGILimitAjax.limitApplication + 'kundkannedom/');
                } else {
                    window.location.assign(CGILimitAjax.limitApplication + 'avslag/');
                }
            } else {
                try {
                    $('.application-form-wrapper .cgi-status').html(response.data.message).removeClass('hidden');
                }
                catch {
                    $('.application-form-wrapper .cgi-status').html('Ett oväntat fel har uppstått. Vänligen försök igen eller kontakta kundtjänst om felet återkommer.').removeClass('hidden');
                }
                $('.application-loader').addClass('hidden');
            }
        });
    };
});


document.addEventListener('DOMContentLoaded', () => {
    const signDiv = document.getElementById('credit-limit-sign');

    if (signDiv) {
        const root = createRoot(signDiv);
        const urlParams = new URLSearchParams(window.location.search);
        const transactionId = urlParams.get('transactionId');

        root.render(
            <div className="cgi-login-form-wrapper application-loader-wrapper">
                <div className="cgi-login-form">
                    <CreditLimitSign transaction={transactionId || ''} />
                </div>
            </div>
        );
    }
});
